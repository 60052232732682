@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

ul {
  list-style: none;
  padding: 0;
}
.footer {
  // background-color: rgba(244, 246, 248,.74);
  // background-color: #F4F8FD;
  background-color: #131c21;
  // padding: 72px 0 0 0;

  .footer-top {
    border-bottom: 2px solid #000;
    padding-bottom: 2.8rem;
  }

  .footer-bottom{
    padding: 1rem 0;
    background-color: #000;

    p{
      margin: 0;
      color: rgba(255, 255, 255,.6);
    }
  }
  a {
    padding: 0.5rem 0;
  }
}
.footer-col {
  @media #{$xs-layout} {
    margin-bottom: 1rem;
  }
  .main-heading {
    color: $theme-hover-color;
    margin-bottom: 1.8rem;
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 15px;
    font-weight: 600;
  }

  h4 {
    margin: 0px 0px 25px 0px;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0px;
  }

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 0;
      }
      a {
        &:hover {
          color: $white;
          padding-left: 2px;
        }
      }
      a,
      p {
        text-transform: capitalize;
        text-decoration: none;
        padding: 0.5rem 0;
        display: block;
        transition: all 0.3s ease;
        font-size: 15px;
        font-weight: 500;
        color: #6d6e7b;
        font-style: normal;
      }
    }
  }

  .social-links {
    a {
      display: inline;
      font-size: 1.2rem;
      //   background-color: $theme-color;
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      //   border-radius: 50%;
      color: #ffffff;
      transition: all 0.5s ease;
      //   border: 2px solid $theme-color;
      padding: 10px;

      &:hover {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.3rem;
      }
    }
  }
}

.contact-box {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin: 0.5rem 0;

  &:not(:nth-child(1)) {
    align-items: center;
  }

  .contact-icon {
    color: $theme-color;
    font-size: 0.8rem;
    border: 1px solid $theme-color;
    border-radius: 100%;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 20px;
    transition: all 0.1s;
  }

  .contact-details {
    width: 90%;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }

    a {
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
