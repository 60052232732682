.blog-top-content{

  @media #{$xs-layout}{
    display: flex;
    flex-direction: column;
  }

  .blog-heading{
  @media #{$xs-layout}{
    margin-bottom: 3rem;
    font-size: 1.53rem;
  }
  }
}

.blog-detail-row{
  @media #{$lg-med-layout}{
    flex-direction: column-reverse;
  }
}

.blog_wrapper {
  background-color: #f3f3f3;

  .blog-card{
    box-shadow: 1.91px 9.833px 15px 0px rgb(38 38 38 / 15%);

    .blog-img{
      width: 100%;
      height: 250px;
      overflow: hidden;

      .img-fluid{
        width: 100%;
        height: 100%;
      }
    }

    .card-body{
      background-color: rgba(19, 28, 33,.1);
    }

    .blog-desc{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; 
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  ul {
      margin-bottom: .15rem;
    li {
      font-size: 14px;
      line-height: 1;
      position: relative;
      display: inline-block;
      list-style: outside none none;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #333;
    }
  }

  h4 {
    a {
      font-weight: 500;
      margin: 10px 0;
      color: #333;
      font-size: 19px;

      &:hover{
          color: $theme-hover-color;
      }
    }
  }


  a{
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    margin-top: 4px;
    letter-spacing: .2px;
    text-transform: capitalize;
    color: #333;

    &:hover{
        color: $theme-hover-color;
    }
  }
}


.blog-menu-item{
  .blog-nav-link{
    display: flex;
    gap: 10px;
    align-items: center;

    .icon{
      img{
      border-radius: 4px;
      }
    }
    .blog-data{
      display: flex;
      flex-direction: column;

      .blog-name{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
      }
      .blog-date{
        font-size: 10px;
      }
    }
  }
}