.title {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .title__line {
    width: 20%;
    height: 0.09px;
  }
/* 
  @include atMdScreen {
    width: 40%;
  }

  @include atLgScreen {
    width: 70%;
  }

     */
  .title__heading {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
  }

  /* @include atMdScreen {
    font-size: 1.5rem;
  } */

  .section-title{
    h2{
      margin-bottom: 0;
    }
  }

  .section-para{
    p{
      font-size: 18px;
    }
  }

  .thankyou-box{
    padding-top: 1.5rem;
    img{
      width: 320px;
    }
    .padtop20{
      font-size: 19px;
      color: #333;
    }
    .small-top,
    .happy{ 
      font-size: 17px;
      color: #333;
    }
  }