.services_wrapper{
  .services-card{
    // box-shadow: 0 1px 2px rgb(0 0 0 / 25%), 0 -1px 1px rgb(0 0 0 / 13%);
    // transition: all .3s ease-in;

    // &:hover{
    //   box-shadow: 8px 10px 12px rgb(0 0 0 / 25%), 0 -1px 1px rgb(0 0 0 / 13%);
    // }

    a{
      padding: 0;
    }

    .service-image-div{
      width: 100%;
      height: 280px;
      overflow: hidden;
    }

    h3{
      font-size: 20px;
      line-height: 1rem;
      font-weight: 600;
      margin: 1rem 0;

      a{
        color: $theme-color;
        transition: all .2s ease-in;

        &:hover{
          color: $theme-hover-color;
        }
      }
      
    }
  }

  .card-body{
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .about-icon{
      font-size: 45px;
      color: $theme-color;
    }

    .about-para{
      font-size: 19px;
      color: #333;
      font-weight: 500;
      text-align: left;
    }
  }
}

.services-bg {
  background-image: linear-gradient(
      to right bottom,
      rgba(33, 62, 77, 0.7),
      rgba(33, 62, 77, 0.7)
    ),
    url(../images/bread-bg.jpg);
  // background: rgba(68, 68, 68, 0.8);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5rem;
}

.services_background-banner-1{
  // background-image: linear-gradient(
  //     to right bottom,
  //     rgba(33, 33, 33, 0.7),
  //     rgba(66, 66, 66, 0.7)
  //   ),
  //   url(../images/service/servicebanner1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5rem;
}

.services_page_wrapper {
  .service-list-card{
    box-shadow: 0 3px 5px rgb(85 85 85 / 20%);
    // margin-bottom: 5rem;

    &:hover{
      .img-fluid{
        transform: scale(1.1);
      }
    }
  }
  .img-div {
    width: 100%;
    height: 250px;
    overflow: hidden;
    overflow: hidden;

    .img-fluid {
      height: 100%;
      width: 100%;
    }
  }
  .service-type {
    text-transform: uppercase;
    font-weight: 600;
    font-size: .9rem;
    color: $theme-hover-color;

  }
  .service-name{
    a{
      color: #333;
      transition: all .3s ease-in-out;

      &:hover{
        color: $theme-hover-color;
      }
    }
  }
  .service-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .catalog__filter__widget__content__item{
    // margin-bottom: 13px;
    transition: all .3s ease-in-out;

    // &:hover{
    //   color: $theme-hover-color;
    //   border-bottom: 1px solid $theme-color;
    // }
  }
}

.servicepage_wrapper {
  background-color: #fff;
  .img-div {
    width: 100%;
    height: 325px;
    overflow: hidden;

    .img-fluid {
      width: 100%;
      height: 100%;
    }
  }

  .servicepage-description{
    display: flex;
    align-items: center;
  }
  .content-div {
    .servicepage-img{
      width: 100%;
      border: 1px solid red;
      height: 350px;
    }
    .service-name {
      font-size: 22px;
      font-weight: 700;
      line-height: 34px;
      margin-bottom: 1px;
      letter-spacing: 0;
    }

    .service-brochure{
      width: fit-content;
      padding: .5rem 1rem;
      border: 1px solid #999;
      vertical-align: middle;
      margin: .5rem 0;
      transition: all .4s;
      cursor: pointer;

      &:hover{
        border-color: $theme-color;

        span{
          color: $theme-color;
        }
      }

      span{
        font-size: 16px;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        color: #999;
        margin: 0 .3rem;
      }
    }

    .service-best{
     
      .accordion__button{
        padding: 12px;
    
        border-bottom: 1px solid #9999990d;
      }
      .accordion__panel {
        padding: 15px;
      }

    }

    .about-quality{
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
    }
  }
  li {
    font-weight: 500;
    position: relative;
    padding-left: 1.3em;
    margin: 0 0 5px;
    color: #333333da;

    &:before {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 0;
      top: 0;
      content: "\f105";
      font-family: FontAwesome;
      font-size: 20px;
      color: $theme-color;
    }
  }
}
