.faqs {
    padding: 50px 0;
  
    .faq-flex{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  
    .accordion-item {
      border: 1px solid lightgrey;
      margin: 0.5rem 0;
      border-radius: 5px;
      background-color: #ffffff;
      width: 99%;
      overflow: hidden;
  
      @media #{$lg-med-layout}{
          width: 100%;
      }
  
      .accordion-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: .5rem 0.5rem;
        background: white;
        border: none;
        border-radius: 15px;
        outline: none;
        color: $theme-color;
        font-size: 1.2rem;
        text-align: left;
        cursor: pointer;
        transition: 0.1s;
  
        .accordion-caption {
          font-weight: 600;
          color: #333333;
          font-size: 1.2rem;
          margin-bottom: 0;

          span{
            // padding: 0 1rem;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.3;
            color: #000;

            @media #{$xs-med-layout}{
              font-size: 1rem;
          }
          }
  
          @media #{$xs-med-layout}{
              font-size: 1.2rem;
              font-weight: 500;
          }
        }
  
        .accordion-icon {
          border-radius: 50%;
          transform: rotate(0deg);
          transition: 0.3s ease-in-out;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          opacity: 0.9;
          width: 28px;
          height: 28px;
          flex: 0 28px;
          color: $white;
          background-color: $theme-color;
        //   background-color: linear-gradient(90deg, #A32BB4 0%, #854CD2 100%);
        }
      }
      .accordion-content p {
          padding: 0 1rem;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3;
      }
    }
  }
  