.main-header {
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255,1);
    padding: 0 1rem;
    z-index: 5;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #d3d3d0;

    @media #{$xs-med-layout}{
      height: 3.5rem;
      padding: 2rem 0;
    }
  }

  .main-header.active{
    background: rgba(255, 255, 255,1);
  }


  .topbar{
    // background-color: $theme-color;
    padding: .5rem 1rem;
    // color: #fff;
    border-bottom: 1px solid #d3d3d0;

    .topbar-area{
      display: flex;
      justify-content: space-between; 
      align-items: center;

      .topbar-email{
        a{
          color: #333;
          font-size: 22px;
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;
          text-transform: uppercase;
        }
        p{
          margin-bottom: 0;
          color: #000;
          font-weight: 500;
          font-size: 15px;
        }
      }

      @media #{$xxs-md-layout}{
        align-items: center;
        flex-direction: column;
      }
    }
  }
  
  @media (min-width: 768px) {
    .main-header {
      justify-content: space-between;
      padding: 2rem 2rem;
    }
    // .topbar{
    //   padding: 1rem 2.5rem;
    // }
  }

  @media (min-width: 991px) {
    .main-header {
      justify-content: space-between;
      padding: 0 6rem;
    }

    // .topbar{
    //   padding: .5rem 6.5rem;
    // }
  }

  @media (max-width: 991px) {
    .main-header{
      display: none;
    }
  }

