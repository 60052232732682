.enquiry{
    max-width: 700px;
    margin: 2rem auto 1rem;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 7px rgb(0 0 0 / 25%);

    h3{
        font-size: 20px;
        font-weight: 600;
        line-height: 0;
        margin-bottom: 1.5rem;
    }

    .form-control{
        margin: .25rem 0 0 0;
    }
}