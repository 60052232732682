.banner_wrapper {
  .banner-title {
    font-size: 62px;
    color: #161616;
  }
  .banner-para {
    font-size: 20px;
  }
}

@media (max-width: 425px) {
  .banner-title {
    line-height: 52px;
    font-size: 35px !important;
  }
  .banner-para {
    font-size: 16px !important;
  }
}
